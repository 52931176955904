import { createContext, useContext } from 'react'
import { VerificationLocation } from 'store/applicant_verification'
import { PrimaryIdDetails } from 'interfaces/applicant.interface'
import { DisplayedTip } from 'shared_components/ApplicantVerification/interfaces/automated_verification.interface'

export enum AutomatedVerificationStep {
  SelectDevice = 'select_device',
  IdentityVerification = 'identity_verification',
  ProofOfAddress = 'proof_of_address',
  FormAuthorization = 'form_authorization'
}

export enum IdentityVerificationStep {
  PrimaryFront = 'PrimaryFront',
  OCRPrimaryFront = 'OCRPrimaryFront',
  ReviewPrimaryFront = 'ReviewPrimaryFront',
  PrimaryBack = 'PrimaryBack',
  ReviewPrimaryBack = 'ReviewPrimaryBack',
  Selfie = 'Selfie',
  ReviewSelfie = 'ReviewSelfie',
  Verifying = 'Verifying'
}

export enum ProofOfAddressStep {
  IdentityVerificationSuccessful = 'IdentityVerificationSuccessful',
  SecondaryID = 'SecondaryID',
  ReviewSecondaryID = 'ReviewSecondaryID',
  Verifying = 'Verifying'
}

export enum IdTypes {
  StateIdCard = 'state_id_card',
  USPassport = 'us_passport',
  USPassportCard = 'us_passport_card',
  USPermResidentIdCard = 'us_perm_resident_id_card',
  DriversLicense = 'drivers_license',
  VehicleRegistrationCard = 'vehicle_registration_card',
  VehicleInsurancePolicy = 'vehicle_insurance_policy',
  VoterRegistrationCard = 'voter_registration_card',
  CurrentLease = 'current_lease',
  NonUSPassport = 'non_us_passport'
}

export interface AutomatedVerificationContextInterface {
  isApplicantVerified: boolean
  location: VerificationLocation
  primaryId: IdTypes
  secondaryId: IdTypes
  primaryIdName: string
  secondaryIdName: string
  hasPrimaryIDBackside?: boolean
  currentAutomatedVerificationStep: AutomatedVerificationStep
  displayedTips?: DisplayedTip
  isVideoPermissionDenied: boolean | null
  setDisplayedTips: (newValue: DisplayedTip | undefined) => void
  setCurrentAutomatedVerificationStep: (step: AutomatedVerificationStep) => void
  setOpenAutomatedVerificationDialog: (newValue: boolean) => void
  setIsVideoPermissionDenied: (newValue: boolean) => void
  identityVerificationStep: IdentityVerificationStep
  setIdentityVerificationStep: (newValue: IdentityVerificationStep) => void
  proofOfAddressStep: ProofOfAddressStep
  setProofOfAddressStep: (newValue: ProofOfAddressStep) => void
  documentToUpdate: PrimaryIdDetails | null
  setDocumentToUpdate: React.Dispatch<React.SetStateAction<PrimaryIdDetails | null>>
  isSelfieOnlyFlowAvailable?: boolean
  isSimpleSelfieOnlyCheckPossible?: boolean
  onFinish: () => void
}

export const AutomatedVerificationContext = createContext<AutomatedVerificationContextInterface | null>(null)

export const useAutomatedVerificationContext = (): AutomatedVerificationContextInterface => {
  const context = useContext(AutomatedVerificationContext)

  if (context === null) {
    throw new Error('Missing <AutomatedVerificationContext.Provider />')
  }

  return context
}

export default useAutomatedVerificationContext
